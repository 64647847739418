<template>
     <div class="px-2">
        <v-container class="py-0">
            <v-row class="mb-0 px-4">
                <v-col cols="12" class="header px-0 pb-0 pt-3">
                    <span class="header-title">Facturen</span>
                </v-col>
                <v-col cols="12" class="ma-0 pt-3 px-0 sort-container">
                    <v-btn plain
                        class="mr-4 pa-0 black white--text text-capitalize text-start"
                        @click="currentSort = 'roadtrip'">
                        Roadtrip
                        <v-icon large right dark class="pr-2">{{currentSort == 'roadtrip' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                    </v-btn>
                    <v-btn plain
                        class="mr-4 pa-0 black white--text text-capitalize text-start"
                        @click="currentSort = 'type'">
                        Type
                        <v-icon large right dark class="pr-2">{{currentSort == 'type' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                    </v-btn>
                    <v-btn plain
                        class="mr-4 pa-0 black white--text text-capitalize text-start"
                        @click="currentSort = 'bedrag'">
                        Bedrag
                        <v-icon large right dark class="pr-2">{{currentSort == 'bedrag' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                    </v-btn>
                     <v-btn plain
                        class="pa-0 black white--text text-capitalize text-start"
                        @click="currentSort = 'datum'">
                        Datum
                        <v-icon large right dark class="pr-2">{{currentSort == 'datum' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(invoice, index) in sortedArray" :key="index">
                     <v-card class="invoice-card" :class="{ 'rp-red' : invoice.isCompanyExpense }">
                        <v-card-title
                        class="subtitle-1 cols-12 pt-2 pb-0">
                            {{ getCardText(invoice) }}
                        </v-card-title>
                        <v-list class="pa-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ getCardSubText(invoice) }}</v-list-item-title>
                                </v-list-item-content>
                               
                                <v-list-item-action>
                                    <div>
                                        <img class="delete-icon" :src="downloadIcon" alt="Download" />
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import FormatMixin from "@/mixins/format.mixin";
export default {
    name: 'InvoiceList',
    components: 
    {
    },
    mixins: [FormatMixin],
    data () {
        return {
            downloadIcon: require('@/assets/icons/download.svg'),
            deleteIcon: require('@/assets/icons/delete.svg'),
            currentSort: 'roadtrip',
            invoices: [
                {
                    number: "0070",
                    roadtripName: "Ardennen Tour",
                    type: "Volvo",
                    amountPaid: 980.00,
                    isCompanyExpense: true,
                    expenseType: "Lunchpakketten",
                    receiveOn: "2018-09-28",
                },
                {
                    number: "0069",
                    roadtripName: "Eifel Tour",
                    type: "Porsche",
                    participant: "Jedalisa Sevilla",
                    isCompanyExpense: false,
                    amountPaid: 120.00,
                    receiveOn: "2018-08-25",
                },
                {
                    number: "0070",
                    roadtripName: "Ardennen Tour",
                    type: "Volvo",
                    participant: "Marijn van der Leer",
                    isCompanyExpense: false,
                    amountPaid: 950.00,
                    receiveOn: "2018-09-28",

                },
                {
                    number: "0072",
                    roadtripName: "Deventer Tour",
                    type: "Ferrari",
                    participant: "Ronald van Hameren",
                    isCompanyExpense: false,
                    amountPaid: 360.00,
                    receiveOn: "2019-01-05",
                }
            ]
        }
        
    },
    props: 
    {
    },
    computed: 
    {
        sortedArray() 
        {
            let sortedInvoices = this.invoices;
		
            sortedInvoices = sortedInvoices.sort((a,b) => {
                let text1 = "",
                    text2 = "";

                switch(this.currentSort)
                {
                    case "roadtrip":
                        text1 = a.number.toLowerCase();
                        text2 = b.number.toLowerCase();
                        break;
                    case "type":
                        text1 = a.isCompanyExpense ? 0: 1;
                        text2 = b.isCompanyExpense ? 0: 1;
                        break;
                    case "bedrag":
                        text1 = a.amountPaid.toLowerCase();
                        text2 = b.amountPaid.toLowerCase();
                        break;
                    case "datum":
                        text1 = a.receiveOn.toLowerCase();
                        text2 = b.receiveOn.toLowerCase();
                        break;
                }

                if (text1 < text2) {
                    return -1
                }
                if (text1 > text2) {
                    return 1
                }
                return 0
            })
            return sortedInvoices;
        }
    },
    methods: {
        getCardText(item)
        {
            let label = item.isCompanyExpense ? item.expenseType : item.participant;
            return `${item.number} | ${item.roadtripName} | ${label}`;
        },
        getCardSubText(item)
        {
            let label = item.isCompanyExpense ? `Betaald:` : `Ontvangen:`;
            return `Bedrag: ${this.getCurrency(item.amountPaid)} | ${label}: ${this.formatDate(item.receiveOn)}`;
        }

    },
}
</script>
<style>
.theme--dark.v-card.invoice-card,
.invoice-card .theme--dark.v-list
{
    background-color: #005E39;
}

.theme--dark.v-card.invoice-card.rp-red,
.invoice-card.rp-red .theme--dark.v-list
{
    background-color: #6B0017;
}

.invoice-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.invoice-card 
{
    border-radius: 10px;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}
</style>

<template>
    <div v-if="isAdmin">
        <LoadingIcon :isLoading="isLoading" />
        <AccountDetailsForm v-if="accountId" :email="email" :accountId="accountId" :personInfo="personInfo" :accountAddress="accountAddress" />
        <PassengerList  v-if="accountId" :accountId="accountId" />
        <CarList />
        <InvoiceList />
        <div class="px-2">
            <v-container>
                <!-- Delete Account Admin -->
                <v-row class="mb-0 px-4">
                    <v-col cols="12" class="display-1 px-0 pb-0 pt-3">
                        <span class="header-title">Account blokkeren of verwijderen</span>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-3 px-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </v-col>
                    <v-col  cols="12" class="pt-3 px-0" v-if="!isBlocked">
                        <v-btn color="primary" block @click="confirmBlock()">
                        Account blokkeren
                        </v-btn>
                    </v-col>
                    <v-col  cols="12" class="pt-3 px-0" v-if="isBlocked">
                        <v-btn color="primary" block @click="confirmUnblock()">
                        Account niet blokkeren
                        </v-btn>
                    </v-col>
                    <v-col  cols="12" class="pt-3 px-0">
                        <v-btn block @click="confirmDelete()">
                        Account verwijderen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <MessageActionDialog @yes="onAction()" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountDetailsForm from "@/components/dashboard/AccountDetailsForm";
import CarList from "@/components/dashboard/CarList";
import PassengerList from "@/components/dashboard/PassengerList";
import InvoiceList from "@/components/admin/InvoiceList";
import LoadingIcon from '@/components/common/LoadingIcon';
import MessageActionDialog from "@/components/dialogs/MessageActionDialog";
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";

export default {
    name: 'Accountgegevens',
    components: 
    {
        CarList,
        PassengerList,
        InvoiceList,
        AccountDetailsForm,
        MessageActionDialog,
        LoadingIcon
    },
    data: () => ({
        accountId: '',
        email: '',
        personInfo: { foodRestrictions: []},
        accountAddress: {},
        isLoading: false,
        action: '',
        isBlocked: false, // TODO: get account if blocked or not
    }),
    computed: {
        ...mapState('authorizedModule', ['isAdmin']),
    },
    mixins: [DataMixin, RouteMixin],
    mounted () 
    {
        this.accountId = this.$route.params.accountId;

        // get account
        this.$store.dispatch('accountModule/getAccountOwnerBasePersoninfo', this.accountId)
        .then((data) => {
            this.personInfo = data;
        });

        this.$store.dispatch('accountModule/getAddressFromAccountowner',  this.accountId)
        .then((data) => {
            this.accountAddress = data;
        });

        this.$store.dispatch('accountModule/getEmailAddressFromAccountOwner', this.accountId)
        .then(email => {
            this.email = email;
        })
    
    },
    methods: 
    {
        confirmBlock()
        {
            this.$store.commit('dialog/showActionDialog', `Weet u zeker dat het account wilt blokkeren? Account Id: '${this.accountId}'.`);
            this.action = "block";
        },
        confirmUnblock()
        {
            this.$store.commit('dialog/showActionDialog', `Weet u zeker dat het account wilt niet blokkeren? Account Id: '${this.accountId}'.`);
            this.action = "unblock";
        },
        confirmDelete()
        {
            this.$store.commit('dialog/showActionDialog', `Weet u zeker dat het account wilt verwijderen? Account Id: '${this.accountId}'.`);
            this.action = "delete";
        },
        onAction()
        {
            switch(this.action)
            {
                case "block":
                    this.blockAccount();
                    return;
                case "unblock":
                    this.unblockAccount();
                    return;
                case "delete":
                    this.deleteAccount();
                    return;    
            }
        },
        deleteAccount()
        {
            this.isLoading = true;
            this.$store.dispatch('accountModule/removeAccount', this.accountId)
            .then(() => {
                this.$store.commit('dialog/showDialog', 'Account is deleted.');
                this.isLoading = false;
                this.goToAccounts();
            });
        },
        blockAccount()
        {
            this.isLoading = true;
            this.$store.dispatch('accountModule/blockAccount', this.accountId)
            .then(() => {
                this.$store.commit('dialog/showDialog', 'Account is blocked.');
                this.isBlocked = true;
                this.isLoading = false;
            })
            .catch(() => {
                this.$store.commit('dialog/showDialog', 'Something went wrong. Unable to block account.');
            })
        },
        unblockAccount()
        {
            this.isLoading = true;
            this.$store.dispatch('accountModule/unblockAccount', this.accountId)
            .then(() => {
                this.$store.commit('dialog/showDialog', 'Account is unblocked.');
                this.isBlocked = false;
                this.isLoading = false;
            })
            .catch(() => {
                this.$store.commit('dialog/showDialog', 'Something went wrong. Unable to unblock account.');
            })
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}
</style>
<template>
    <div class="px-2 break-word">
        <v-container v-if="!isLoading">
            <v-row no-gutters>
                <v-col>
                    <p class="display-1 mb-2">Accountgegevens</p>
                    <div class="mb-3">{{intro}}</div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="my-2 header-title font-weight-medium">
                    Logingegevens
                </v-col>
                <v-col cols="5" class="ma-auto pa-0">
                    E-mailadres
                </v-col>
                <v-col cols="7" class="ma-0 pr-1 text-end">
                    {{email}}
                </v-col>
                <v-col class="py-4">
                    <v-btn color="primary" block @click="goToChangePassword()">
                    Wachtwoord wijzigen
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="!isAdmin || isSubscribedToNewsletter != null">
                <v-col cols="12" class="my-2 header-title font-weight-medium">
                    Nieuwsbrief abonnement
                </v-col>
                <v-col cols="6" class="ma-auto pa-0">
                    Geabonneerd
                </v-col>
                <v-col cols="6" class="ma-0 pr-1 text-end">
                    {{isSubscribedToNewsletter ? `Ja` : `Nee`}}
                </v-col>
                <v-col class="py-4">
                    <v-btn :class="isSubscribedToNewsletter ? `` : `primary`" block @click="setSubscription()" :loading="isSubscribing">
                    {{isSubscribedToNewsletter ? `Afmelden` : `Abonneren`}}
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Person Details -->
            <v-form ref="personform" lazy-validation v-model="personformvalid">
                <v-row no-gutters>
                    <v-col cols="12" class="header px-0 pt-3">
                        <span class="header-title font-weight-medium">Persoonsgegevens</span>
                        <v-spacer></v-spacer>
                        <div>
                            <v-icon v-if="editPersonDetails" @click="onEditPersonDetails()"
                                right size="20" class="mt-2"
                                dark>
                                mdi-check
                            </v-icon>
                            <img class="edit-icon mt-3" :src="editIcon" alt="Edit" v-if="!editPersonDetails" @click="onEditPersonDetails()" />
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Voornaam
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editPersonDetails">{{personInfo.firstname}}</div>
                        <v-text-field v-if="editPersonDetails" flat
                            class="ma-0 pa-0"
                            v-model="personInfo.firstname"
                            @keypress="isNonNumeric($event)"
                            :rules="[rules.required, rules.nameLength, rules.validName]"
                            required
                            @keyup.enter.stop="onEditPersonDetails()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Achternaam
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editPersonDetails">{{personInfo.lastName}}</div>
                        <v-text-field v-if="editPersonDetails" flat
                            class="ma-0 pa-0"
                            v-model="personInfo.lastName"
                            @keypress="isNonNumeric($event)"
                            :rules="[rules.required, rules.nameLength, rules.validName]"
                            required
                            @keyup.enter.stop="onEditPersonDetails()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters> 
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        Geboortedatum
                    </v-col>
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        <div v-if="!editPersonDetails">{{formatDateWithoutTime(personInfo.dateOfBirth)}}</div>
                        <div v-show="editPersonDetails">
                            <DatepickerFormatted 
                            @changed="personInfo.dateOfBirth = $event" 
                            :date="getDateOnly(personInfo.dateOfBirth)" 
                            :isRequired="true" 
                            :showIcon="true" 
                            :isBirthdate="true"
                            :isAccountHolder="true" />
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        Telefoonnummer
                    </v-col>
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        <div v-if="!editPersonDetails">{{personInfo.phoneNumber}}</div>
                        <v-text-field v-if="editPersonDetails" flat
                            class="ma-0 pa-0"
                            v-model="personInfo.phoneNumber"
                            @keypress="isPhoneNumberKey($event)"
                            :rules="[rules.phoneNumberLength]"
                            :readonly="editPersonDetails == false"
                            @keyup.enter.stop="onEditPersonDetails()"
                            ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>


            <!-- Address -->
            <v-form ref="addressform" lazy-validation v-model="addressformvalid">
                <v-row no-gutters>
                    <v-col cols="12" class="header px-0 pt-3">
                        <span class="header-title font-weight-medium">Adresgegevens</span>
                        <v-spacer></v-spacer>
                        <div>
                            <v-icon v-if="editAddress" @click="onEditAddress()"
                                right size="20" class="mt-2"
                                dark>
                                mdi-check
                            </v-icon>
                            <img class="edit-icon mt-3" :src="editIcon" alt="Edit" v-if="!editAddress" @click="onEditAddress()" />
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Straatnaam
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editAddress">{{accountAddress.street}}</div>
                        <v-text-field v-if="editAddress" flat
                            class="ma-0 pa-0"
                            v-model="accountAddress.street"
                            :rules="[rules.required, rules.maxLength(accountAddress.street, 40)]"
                            required
                            @keyup.enter.stop="onEditAddress()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Huisnummer
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editAddress">{{accountAddress.houseNumber}}</div>
                        <v-text-field v-if="editAddress" flat
                            class="ma-0 pa-0"
                            v-model="accountAddress.houseNumber"
                            :rules="[rules.required, rules.maxLength(accountAddress.houseNumber, 15)]"
                            required
                            @keyup.enter.stop="onEditAddress()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Postcode
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editAddress">{{accountAddress.zipCode}}</div>
                        <v-text-field v-if="editAddress" flat
                            class="ma-0 pa-0"
                            v-model="accountAddress.zipCode"
                            :rules="[rules.required, rules.maxLength(accountAddress.zipCode, 10)]"
                            required
                            @keyup.enter.stop="onEditAddress()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Plaatsnaam
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editAddress">{{accountAddress.city}}</div>
                        <v-text-field v-if="editAddress" flat
                            class="ma-0 pa-0"
                            v-model="accountAddress.city"
                            :rules="[rules.required, rules.maxLength(accountAddress.city, 40)]"
                            required
                            @keyup.enter.stop="onEditAddress()"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        Land
                    </v-col>
                    <v-col cols="6" class="ma-auto py-3 px-0">
                        <div v-if="!editAddress">{{getCountryName(accountAddress.country)}}</div>

                        <div v-if="editAddress">
                            <CountryDropdown :country="accountAddress.country" :hideLabel="true" @changed="accountAddress.country = $event"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        Bedrijfsnaam
                    </v-col>
                    <v-col cols="6" class="ma-auto py-2 px-0">
                        <div v-if="!editAddress">{{accountAddress.company}}</div>
                        <v-text-field v-if="editAddress" flat
                            class="ma-0 pa-0"
                            :rules="[rules.maxLength(accountAddress.company, 40)]"
                            v-model="accountAddress.company"
                            @keyup.enter.stop="onEditAddress()"
                            ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <!-- Allergy -->
            
            <v-row no-gutters>
                <v-col cols="12" class="header px-0 pt-3">
                    <span class="header-title font-weight-medium">Allergieën en voedselvoorkeuren</span>
                    <v-spacer></v-spacer>
                </v-col>
                <v-col cols="12" class="ma-auto py-2 px-0">
                   <FoodRestrictionDropdown 
                    :allergies="personInfo.foodRestrictions" 
                    @changed="updateAllergy($event)" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import RouteMixin from "@/mixins/route.mixin";
import DatepickerFormatted from '@/components/common/DatepickerFormatted';
import FoodRestrictionDropdown from '@/components/common/options/FoodRestrictionDropdown';
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import CountryDropdown from "@/components/common/options/CountryDropdown";

export default {
    name: 'AccountDetailsForm',
    components: 
    {
        CountryDropdown,
        DatepickerFormatted,
        FoodRestrictionDropdown
    },
    props: {
        accountId: String,
        email: String,
        personInfo: Object,
        accountAddress: Object,
        isSubscribed: Boolean
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        intro: "Hieronder ziet u al uw persoonlijke accountgegevens. Is er iets gewijzigd? U kunt het hieronder eenvoudig aanpassen.",
        valid: true,
        editPersonDetails: false,
        editAddress: false,
        editAllergy: false,
        personformvalid: false,
        addressformvalid: false,
        isLoading: false,
        isSubscribedToNewsletter: null,
        isSubscribing: false
    }),
    computed: {
        ...mapState('account', ['status']),
        ...mapState('authorizedModule', ['isAdmin']),
    },
    watch: {
        isSubscribed: {
            deep: true,
            handler() {
                this.isSubscribedToNewsletter = this.isSubscribed;
            }
        },
    },
    mixins: [DataMixin, GlobalMixin, RouteMixin, FormatMixin, MenuHelperMixin],
    methods: 
    {
        getPersonRequest()
        {
            let request = {
                personInfoRequest: {
                    accountId: this.accountId,
                    firstname: this.personInfo.firstname,
                    lastName: this.personInfo.lastName,
                    dateOfBirth: this.personInfo.dateOfBirth,
                    phoneNumber: this.personInfo.phoneNumber,
                    id: this.personInfo.personId
                },
                foodInfoRequest: {
                    personId: this.personInfo.personId,
                    foodRestrictions: this.personInfo.foodRestrictions
                }
            }
            return request;
        },
        onEditPersonDetails()
        {
            const valid = !this.editPersonDetails || this.$refs.personform.validate();
            if(!valid)
            {
                return;
            }

            this.editPersonDetails = !this.editPersonDetails;

            if(!this.editPersonDetails && this.status.loggedIn)
            {
                this.$store.dispatch('accountModule/updatePersonInfo', this.getPersonRequest());
            }
        },
        onEditAddress()
        {
            const valid = !this.editAddress || this.$refs.addressform.validate();
            if(!valid)
            {
                return;
            }

            this.editAddress = !this.editAddress;

            if(!this.editAddress && this.status.loggedIn)
            {
                const request = {
                    accountId: this.accountId,
                    street: this.accountAddress.street,
                    houseNumber: this.accountAddress.houseNumber,
                    zipCode: this.accountAddress.zipCode,
                    city: this.accountAddress.city,
                    country: this.accountAddress.country,
                    company: this.accountAddress.company,
                }
                this.$store.dispatch('accountModule/updateAddressFromAccountowner', request);
            }
        },
        updateAllergy(allergies)
        {
            if(!this.status.loggedIn) return;

            this.personInfo.foodRestrictions = allergies;
            this.$store.dispatch('accountModule/updatePersonInfo', this.getPersonRequest());
        },
        setSubscription()
        {
            if(!this.status.loggedIn) return;

            this.isSubscribing = true;
            if (this.isSubscribedToNewsletter)
            {
                // unsubscribe
                this.$store.dispatch('newsletterModule/unsubscribeExistingAccountFromNewsletter', this.accountId)
                .then(isSuccess => {
                    this.isSubscribing = false;
                    this.isSubscribedToNewsletter = !isSuccess;

                    if(isSuccess)
                    {
                        this.$store.commit('dialog/showDialog', "U heeft zich succesvol afgemeld voor de nieuwsbrief. U ontvangt hierover een bevestiging in uw inbox.");
                    }
                    else
                    {
                        this.$store.commit('dialog/showDialog', "Er is iets misgegaan bij het afmelden van de nieuwsbrief.");
                    }
                })
                .catch(() => {
                    this.isSubscribing = false;
                    this.$store.commit('dialog/showDialog', "Er is iets misgegaan bij het afmelden van de nieuwsbrief.");
                })
            }
            else
            {
                // subscribe
                this.$store.dispatch('newsletterModule/subscribeExistingAccountToNewsletter', this.accountId)
                .then(isSuccess => {
                    this.isSubscribing = false;
                    this.isSubscribedToNewsletter = isSuccess;

                    if(isSuccess)
                    {
                        this.$store.commit('dialog/showDialog', "U heeft zich succesvol aangemeld voor de nieuwsbrief. U ontvangt hierover een bevestiging in uw inbox.");
                    }
                    else
                    {
                        this.$store.commit('dialog/showDialog', "Er is iets misgegaan bij het aanmelden van de nieuwsbrief.");
                    }
                })
                .catch(() => {
                    this.isSubscribing = false;
                    this.$store.commit('dialog/showDialog', "Er is iets misgegaan bij het aanmelden van de nieuwsbrief.");
                })
            }
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}


</style>